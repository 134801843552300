const translations = {
  es: {
    dashboard: {
      messageSyncStatus: 'Usuario sincronizado',
      messageNotSyncStatus: '¡Tu usuario no está sincronizado!',
      messageSync: 'Usuario sincronizado correctamente',
      messageNotSync: 'Error al sincronizar el usuario',
      evaluationNotFound: 'Error no se encontró evaluaciones',
      errorGettingInfos: 'Error al traer las informaciones del usuario, intente novamente',
      userHaventEvaluations: 'Usted aún no tiene evaluaciones',
      histogramTitle: 'Historico de evaluaciones',
      pentagramGraph: 'Vision general por año',
      searchPlaceholder: 'Buscar Evaluaciones',
      administration: 'Administración',
      romboTitle: 'Vision general por año',
      alreadyExists: '¡El elemente ya existe!',
      unasigned: 'No asignados',
      golbalView: 'Visión general',
      saveTeam: 'Guardar Time',
      teamModalTitle: 'Sus times',
      confirmRemove: '¿Estás seguro que desas borrar este time?',
      teamSaved: '¡Time salvo con suceso!',
      teamRemoved: '¡Time borrado con suceso!',
      profileDetailsEdit: 'Editar perfil',
      companyDetailsEdit: 'Editar empresa',
      profileEditLabel: 'Configuración de perfil',
      profileEditName: 'Nombre',
      profileEditPass: 'Nueva contraseña',
      profileEditConfirmPass: 'Confirmar nueva contraseña',
      profileEditErrorPassword: 'Las contraseñas no coinciden',
      companyEditLabel: 'Configuración de la organización',
      companyEditName: 'Nombre de la organización',
      companyRelation: 'Habilitar relaciones'
    },
    AdminUsers: {
      searchPlaceholder: 'Buscar developers',
      title: 'Mis Developers',
      columnName: 'Nombre',
      columnType: 'Tipo',
      columnCareerType: 'Tipo de carrera',
      columnCharge: 'Cargo',
      columnLevel: 'Nível',
      columnActive: 'Activo',
      daysLeftMessage: 'Proximo cambio de funcción disponible en: ',
      allChecksNotAllowedMsg: '¡Solo se puede chequear todas las skills si el tiempo minimo de permanencia se ha cumprido!',
      hired: 'Contratado desde',
    },
    AdminEvaluations: {
      evaluations: 'Evaluaciones',
      evaluation: 'Evaluacion',
      searchPlaceholder: 'Buscar evaluaciones',
      title: 'Administración de evaluaciones',
      columnName: 'Name',
      columnDate: 'Fecha',
      columnEvaluation: 'Evaluation',
      columnActive: 'Activo',
      abaGeneral: 'General',
      abaPerformance: 'Desempeño',
      abaHumanFactor: 'Factor Humano',
      abaSkills: 'Habilidades',
      performanceArray: [
        'Responsabilidad',
        'Exactitud y calidad',
        'Entregas en fecha',
        'Productividad',
        'Orden y claridad del trabajo',
        'Status de su trabajo',
        'Capacidad de realización',
        'Comprensión de situaciones',
        'Sentido Común',
        'Cumplimiento de los procedimientos existentes',
        'Grado de Conocimiento Técnico',
        'Grado de Conocimiento funcional',
      ],
      HumanFactorArray: [
        'Actitud hacia la Empresa',
        'Actitud hacia superiores',
        'Actitud hacia los Compañeros',
        'Actitud con el cliente',
        'Cooperación con el equipo',
        'Capacidad de aceptar críticas',
        'Capacidad de generar sugerencias constructivas',
        'Predisposición',
      ],
      skillsArray: [
        'Iniciativa',
        'Creatividad',
        'Adaptabilidad',
        'Respuesta bajo presión',
        'Capacidad de manejar múltiples tareas',
        'Coordinación y Liderazgo',
        'Potencialidad',
      ],
			copyEvaluation: '¿Desea crear una copia de esta evaluación?'
    },
    AdminTechnologies: {
      placeholder:'Adicionar nueva tecnología',
      tableHeaders: ['id', 'nombre', 'plataforma', ''],
      title: 'Tecnologías en Lexart',
      success: '¡Operación completada con suceso!',
      error: 'No fue possible completar la operación.',
    },
    AdminPositions: {
      placeholder:'Agregar una nueva posición',
      placeholder2:'Tiempo mínimo',
      tableHeaders: ['id', 'Posición', 'Empresa', 'Tipo de carrera', 'Roadmap', 'Tiempo mínimo (dias)'],
      title: 'Posicións',
      confirmRemove: '¿Estás seguro que desas borrar esta posición?',
      deleted: 'Posición eliminada con éxito.',
      created: 'Posición creada con éxito.',
      edited: 'Posición editado con éxito.',
      success: '¡Operación completada con suceso!',
      duplicateError: 'Esta posición ya existe',
      deleteError: 'Error: Please make sure this plataform is not related to any user.',
      positionIsUsed: 'This type of position is being used',
      error: 'No fue possible completar la operación.',
      roadmapSuccess: 'Roadmap actualizado con éxito!'
    },
    AdminLevels: {
      placeholder:'Adicionar nuevo level',
      tableHeaders: ['id','nombre', 'career', ''],
      title: 'Levels en Lexart',
      success: '¡Operación completada con suceso!',
      error: 'No fue possible completar la operación.',
      errorDelete: 'No se puede eliminar un nivel relacionado con un usuario'
    },
    AdminOrigins: {
      placeholder: 'Adicionar una plataforma',
      title: 'Orígenes',
      tableHeaders: ['id', 'plataforma'],
      deleteError: 'Error: Por favor, asegúrese de que esta plataforma no esté asociada con ningún usuario.',
    },
    generic: {
      cancel: 'Cancelar',
      save: 'Guardar',
      date: 'Fecha',
      edit: 'Editar',
      close: 'Cerrar',
      import: 'Importar',
      remove: 'Borrar',
      topic: 'Topico',
      score: 'Puntaje',
      order: 'Ordenar',
      observations: 'Observaciones',
      yes: 'SI',
      no: 'NO',
      next: 'Próximo',
      back: 'Atrás',
      exit: 'Cerrar sección',
      technologies: 'Tecnologías',
      hunting: 'Times',
      Positions: 'Posición',
      months: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
      asignments: 'Atributos',
      searchPlaceholderEvaluations: 'Buscar evaluaciones',
      Evaluations: 'Evaluaciones',
      Dashboard: 'Dashboard',
      performance: 'Desempeño',
      humanFactor: 'Factor humano',
      skills: 'Habilidades',
      lead: 'Liderazgo',
      leadTree: 'Mapa de Liderazgo',
      minimumTimeReached: '¡Nuevo cargo disponible!',
      personify: 'Personificar developer',
      search: 'Buscar',
      warning: '¡Atencion!',
      days: 'días',
      origin: 'Origin',
      continuity: 'Continuidade',
      collaborators: 'Colaboradores',
      month: 'Mes',
      year: 'Año',
      hours: 'Horas',
      minutes: 'Minutos',
      seconds:' Segundos',
      all: 'Todos',
      name: 'Nombre',
      userType: 'Tipo usuario',
      password: 'Contraseña',
      payments: 'Pagos',
      careerType: 'Tipo de carrera',
      loading: 'Cargando',
      seeDetails: 'Ver detalles',
      back: 'Atrás',
      next: 'Siguiente',
			copy: 'Copiar',
			confirm: 'Confirmación',
			confirmCopy: '¿Estás seguro que deseas copiar este recurso?'
    },
    positionAssignments: {
      'EntryLevel Developer': [
        'Aprendizaje dentro de proyectos internos',
        'Evolución exponencial dentro de los tres meses de prueba',
        'Respetar horarios según disponibilidad pautada',
        'Proactividad para consultar dudas a programadores experimentados',
        'Comunicación activa',
      ],
      'FrontEnd Developer': [
        'Comprometimiento con tareas pautadas en proyecto',
        'Compromiso con el proyecto',
        'Evolución gradual sobre la tecnología del proyecto',
        'Respetar horarios según disponibilidad pautada',
        'Comunicación activa',
        'Reportar progreso de forma continua a managers',
      ],
      'FullStack Developer': [
        'Comprometimiento con tareas pautadas en proyecto',
        'Compromiso con el proyecto',
        'Autonomía en el proyecto',
        'Capacidad de decisión sobre el proyecto',
        'Respetar horarios según disponibilidad pautada',
        'Comunicación activa',
        'Evacuar dudas de programadores iniciantes',
        'Reportar progreso de forma continua a managers',
        'Ayudar a los managers para definición de tareas',
      ],
      'Jr. Software Architect': [
        'Comprometimiento con tareas pautadas en proyecto',
        'Compromiso con el proyecto',
        'Autonomía en el proyecto',
        'Capacidad de decisión sobre el proyecto',
        'Respetar horarios según disponibilidad pautada',
        'Comunicación activa',
        'Evacuar dudas de programadores',
        'Reportar progreso de forma continua a managers',
        'Ayudar a los managers para definición de tareas',
        'Capacitar a programadores iniciantes',
        'Mantenimiento de ambientes de FrontEnd',
      ],
      'Software Architect': [
        'Comprometimiento con tareas pautadas en proyecto',
        'Compromiso con el proyecto',
        'Autonomía en el proyecto',
        'Capacidad de decisión sobre el proyecto',
        'Respetar horarios según disponibilidad pautada',
        'Comunicación activa',
        'Evacuar dudas de programadores experimentados',
        'Reportar progreso de forma continua a managers',
        'Definición de tareas en proyectos',
        'Capacitar a programadores',
        'Mantenimiento de ambientes de FrontEnd/BackEnd y Base de datos',
        'Planificación de arquitectura de software de proyectos asignados',
        'Reportar a infraestructura necesidades de ambientes',
      ],
      'Lead Software Architect': [
        'Supervisión de procesos tecnológicos en los proyectos',
        'Autonomía para elección de tecnologías según el proyecto',
        'Capacidad de decisión',
        'Liderazgo de equipo',
        'Amplia disponibilidad horaria',
        'Comunicación proactiva',
        'Evacuar dudas de los arquitectos de software',
        'Definición de workflow técnico del proyecto',
        'Mentoría para programadores y arq. de software',
        'Construcción de ambientes BackEnd/FrontEnd/Base de datos desde 0.',
        'Planificación de contingencia y control de riesgos',
        'Reportar a infraestructura necesidades tecnológicas y cambios de gran impacto',
        'Reportería activa de los proyectos al directorio de Lexart',
      ],
      'Solution Architect': [
        'Gestión y definición de procesos tecnológicos en los proyectos',
        'Elección de tecnologías según el proyecto',
        'Capacidad de decisión sobre presión y riesgo',
        'Liderazgo de equipo',
        'Amplia disponibilidad horaria',
        'Comunicación proactiva',
        'Evacuar dudas de los arquitectos de software',
        'Definición de workflow técnico del proyecto',
        'Mentoría para diversos equipos de la empresa',
        'Capacidad para transmitir las necesidades tecnologías a personal no idóneo en el asunto (Comercial, Marketing, etc)',
        'Capacidad para decidir cambios tecnológicos de grande impacto en conjunto con infraestructura',
        'Reportería activa de las decisiones al directorio',
      ],
    },
    AdminContinuity: {
      title: 'Continuidade',
      newBtn: 'Reporte',
      searchPlaceholder: 'Buscar reporte de horas',
      headers: [ 'Id', 'Usuario', 'Mes', 'Año', 'Horas'],
      modalTitle: 'Reportes',
      colaborator: 'Colaborador',
      errorMsgs: {
        month: 'Si requiere un mes',
        year: 'Año invalido',
        user: 'Por favor, elige a un colaborador',
        continuity: 'Valor de horas inválido. Por favor, inserir en el formato HH:MM:SS',
      },
    },
    AdminCareerType: {
      placeholder: 'Adicionar nuevo tipo de carreira',
      title: 'Career Type',
      confirmRemove: '¿Está seguro de que desea eliminar este tipo de carrera?',
      tableHeaders: ['id', 'nombre'],
      deleteError: 'Error: Please make sure this plataform is not related to any user.',
      duplicateError: 'Este tipo de carrera ya existe',
      careerIsUsed: 'Este tipo de carrera se está utilizando',
      created: 'Tipo de carrera creado con éxito.',
      deleted: 'Tipo de carrera eliminado con éxito.',
      edited: 'Tipo de carrera editado con éxito',
    },
    AdminCollaborators: {
      title: 'Colaboradores',
      newBtn: 'Colaborador',
      searchPlaceholder: 'Buscar por colaborador',
      headers: [ 'Id', 'Usuario', 'Mes', 'Año', 'Horas'],
      modalTitle: 'Colaborador',
      colaborator: 'Colaborador',
      successToAdd: "Usuario editado/creado correctamente",
      errorToAdd: "Error al intentar editar/crear un usuario"
    },
    AdminPayments: {
      title: 'Pagos',
      headers: ['Id', 'Nombre', 'Sueldo', 'Moneda', 'Facturación', 'Creado', 'Actualizado'],
      modalTitle: 'Sueldo',
      colaborator: 'Colaborador',
      newBtn: 'Sueldo',
      currency: 'Moneda',
      billing: 'Facturación',
      promotion: 'Fecha de promoción',
      salary: 'Sueldo',
      errorMsgs: {
        salary: '¡Sueldo es requerido!',
        user: 'Por favor, eleige a un colaborador.',
        billing: 'Por favor especificar el periodo de facturación.',
        date: 'Por favor especificar un afecha de promocción.',
        currency: 'Moneda es un campo requerido',
      },
    },
    UserBurnoutTest: {
      warning: 'No has realizado el test de burnout en los últimos 3 meses.',
      newTest: 'Nueva Prueba',
      maxOneTestPerMonth: 'Máximo un test por mes',
      rangesTitle: 'RANGOS DE MEDIDA DE LA ESCALA',
      ranges0: "Nunca.",
      ranges1: "Pocas veces al año o menos.",
      ranges2: "Una vez al mes o menos.",
      ranges3: "Unas pocas veces al mes.",
      ranges4: "Una vez a la semana.",
      ranges5: "Unas pocas veces a la semana.",
      ranges6: "Todos los días.",
      q0: "Me siento emocionalmente agotado/a por mi trabajo",
      q1: "Me siento cansado al final de la jornada de trabajo.",
      q2: "Cuando me levanto por la mañana y me enfrento a otra jornada de trabajo me siento fatigado.",
      q3: "Tengo facilidad para comprender cómo se sienten mis colegas.",
      q4: "Creo que estoy tratando algunas tareas como si fueran objetos impersonales.",
      q5: "Siento que trabajar todo el día con código supone un gran esfuerzo y me cansa.",
      q6: "Creo que manejo con mucha eficacia los problemas técnicos.",
      q7: "Siento que mi trabajo me está desgastando. Me siento quemado por mi trabajo.",
      q8: "Creo que con mi trabajo estoy teniendo un impacto positivo en los proyectos de mi equipo.",
      q9: "Me he vuelto más insensible con las personas desde que empecé a programar.",
      q10: "Pienso que este trabajo me está endureciendo emocionalmente.",
      q11: "Me siento con mucha energía en mi trabajo.",
      q12: "Me siento frustrado/a en mi trabajo",
      q13: "Creo que trabajo demasiado.",
      q14: "Realmente no me preocupa lo que suceda con algunos de mis proyectos.",
      q15: "Trabajar directamente con código me estresa.",
      q16: "Siento que puedo crear fácilmente un ambiente agradable con mi equipo.",
      q17: "Me siento motivado/a después de trabajar en proyectos.",
      q18: "Creo que consigo muchas cosas valiosas en este trabajo.",
      q19: "Me siento acabado en mi trabajo, al límite de mis posibilidades.",
      q20: "En mi trabajo trato los problemas emocionalmente con mucha calma.",
      q21: "Creo que algunos miembros del equipo me culpan por algunos de sus problemas.",
      scoreTired: 'Cansancio emocional',
      scoreDepersonalization: 'Despersonalización',
      scoreRealization: 'Realización personal',
      tooltipScore: 'Indicios de burnout, más de'
    }
  },
  en: {
    dashboard: {
      messageSyncStatus: 'Synchronized user',
      messageNotSyncStatus: 'Your user is not synced!',
      messageSync: 'User successfully synced',
      messageNotSync: 'Failed to sync user',
      evaluationNotFound: 'Error no reviews found',
      userHaventEvaluations: "You haven't evaluations yet",
      errorGettingInfos: 'Error getting user infos, try again',
      histogramTitle: 'Evaluation history',
      pentagramGraph: 'Overview by year',
      searchPlaceholder: 'Search Evaluations',
      administration: 'Administration',
      romboTitle: 'Overview by year',
      alreadyExists: 'This item already exists!',
      unasigned: 'Unasigneds',
      golbalView: 'Global Overview',
      saveTeam: 'Save Team',
      teamModalTitle: 'Your Teams',
      confirmRemove: 'Are you sure you want to remove this team?',
      teamSaved: 'Team successfully saved!',
      teamRemoved: 'Team successfully removed',
      profileDetailsEdit: 'Edit profile',
      companyDetailsEdit: 'Edit company',
      profileEditLabel: 'Profile settings',
      profileEditName: 'Name',
      profileEditPass: 'New password',
      profileEditConfirmPass: 'Confirm new password',
      profileEditErrorPassword: 'Passwords dont match',
      companyEditLabel: 'Company settings',
      companyEditName: 'Company',
      companyRelation: 'Company Relationship'
    },
    AdminUsers: {
      searchPlaceholder: 'Search developers',
      title: 'My Developers',
      columnName: 'Name',
      columnType: 'Type',
      columnCareerType: 'Career Type',
      columnCharge: 'Position',
      columnLevel: 'Level',
      columnActive: 'Active',
      daysLeftMessage: 'Next job change available in: ',
      allChecksNotAllowedMsg: 'A user can not be saved with all skills checked before the minimum trading position time',
      hired: 'Hired from',
    },
    AdminEvaluations: {
      evaluations: 'Evaluations',
      evaluation: 'Evaluation',
      searchPlaceholder: 'Search Evaluations',
      title: 'Assessment management',
      columnName: 'Name',
      columnDate: 'Date',
      columnEvaluation: 'Evaluation',
      columnActive: 'Active',
      abaGeneral: 'General',
      abaPerformance: 'Performance',
      abaHumanFactor: 'Human Factor',
      abaSkills: 'Skills',
      performanceArray: [
        'Responsibility',
        'Accuracy and quality',
        'Deliveries on date',
        'Productivity',
        'Order and clarity of work',
        'Status of your work',
        'Ability to perform',
        'Understanding of situations',
        'Common sense',
        'Compliance with existing procedures',
        'Degree of Technical Knowledge',
        'Degree of functional knowledge',
      ],
      HumanFactorArray: [
        'Attitude towards the Company',
        'Attitude towards superiors',
        'Attitude towards Companions',
        'Attitude towards the client',
        'Cooperation with the team',
        'Ability to accept criticism',
        'Ability to generate constructive suggestions',
        'Predisposition',
      ],
      skillsArray: [
        'Initiative',
        'Creativity',
        'Adaptability',
        'Response under pressure',
        'Ability to handle multiple tasks',
        'Coordination and Leadership',
        'Potentiality',
      ],
			copyEvaluation: 'Do you want to create a copy of this evaluation?'
    },
    AdminTechnologies: {
      placeholder:'Add a new level',
      tableHeaders: ['id', 'name', 'plataform', ''],
      title: 'Lexart stack',
      success: 'Operation ran sucessfully',
      error: 'Error when saving this operation',
    },
    AdminPositions: {
      placeholder:'Add a new position',
      placeholder2:'Minimum time',
      tableHeaders: ['id', 'Position', 'Company', 'Career type', 'Roadmap', 'Minimum time (days)'],
      title: 'Positions',
      confirmRemove: 'Are you sure you want to delete this position?',
      success: 'Operation ran sucessfully',
      deleted: 'Position deleted successfully.',
      created: 'Position created successfully.',
      edited: 'Position edited successfully.',
      error: 'Error when saving this operation',
      duplicateError: 'This position already exists',
      deleteError: 'Error: Por favor, asegúrese de que esta plataforma no esté relacionada con ningún usuario.',
      positionIsUsed: 'Este tipo de posición se está utilizando',
      roadmapSuccess: 'Roadmap edited successfully'
    },
    AdminLevels: {
      placeholder:'Add a new level',
      tableHeaders: ['id','name', 'career', ''],
      title: 'Lexart stack',
      success: 'Operation ran sucessfully',
      error: 'Error when saving this operation',
      errorDelete: "Can't delete a level related to a user",
    },
    AdminOrigins: {
      placeholder: 'Add new plataform',
      title: 'Origins',
      tableHeaders: ['id', 'plataform'],
      deleteError: 'Error: Please make sure this plataform is not related to any user.',
    },
    AdminCareerType: {
      placeholder: 'Add new career type',
      confirmRemove: 'Are you sure you want to delete this career type?',
      title: 'Career Type',
      tableHeaders: ['id', 'name'],
      deleteError: 'Error: Please make sure this plataform is not related to any user.',
      duplicateError: 'This career type already exists',
      careerIsUsed: 'This type of career is being used',
      created: 'Career type created successfully.',
      deleted: 'Career type deleted successfully.',
      edited: 'Career type edited successfully',
    },
    generic: {
      cancel: 'Cancel',
      save: 'Save',
      date: 'Date',
      edit: 'Edit',
      close: 'Close',
      import: 'Import',
      remove: 'Delete',
      topic: 'Topic',
      score: 'Score',
      order: 'Order by',
      observations: 'Observations',
      yes: 'YES',
      no: 'NO',
      next: 'Next',
      back: 'Bcak',
      exit: 'Log out',
      technologies: 'Technologies',
      hunting: 'Teams',
      Positions: 'Position',
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      burnOut: 'Burnout Test',
      asignments: 'Asignments',
      searchPlaceholderEvaluations: 'Search evaluations',
      Evaluations: 'Evaluations',
      Dashboard: 'Dashboard',
      performance: 'Performance',
      humanFactor: 'Human Factor',
      skills: 'Skills',
      lead: 'Lead',
      leadTree: 'Lead\'s Tree',
      minimumTimeReached: 'New position available!',
      personify: 'Personify developer',
      search: 'Search',
      warning: 'Warning',
      days: 'days',
      origin: 'Origin',
      continuity: 'Continuity',
      collaborators: 'Collaborators',
      month: 'Month',
      year: 'Year',
      hours: 'Hours',
      minutes: 'Minutes',
      seconds:' Seconds',
      all: 'All',
      name: 'Name',
      userType: 'User type',
      password: 'Password',
      payments: 'Payments',
      careerType: 'Career Type',
      loading: 'Loading',
      seeDetails: 'See Details',
      back: 'Back',
      next: 'Next',
			copy: 'Copy',
			confirm: 'Confirmation',
			confirmCopy: 'Are you sure you want to copy this resource?'
    },
    positionAssignments: {
      'EntryLevel Developer': [
        'Learning within internal projects',
        'Exponential evolution within three months of testing',
        'Respect schedules according to scheduled availability',
        'Proactivity to consult doubts with experienced programmers',
        'Active communication',
      ],
      'FrontEnd Developer': [
        'Commitment to tasks scheduled in the project',
        'Commitment to the project',
        'Gradual evolution on project technology',
        'Respect schedules according to scheduled availability',
        'Active communication',
        'Report progress continuously to managers',
      ],
      'FullStack Developer': [
        'Commitment to tasks scheduled in the project',
        'Commitment to the project',
        'Autonomy in the project',
        'Decision-making capacity about the project',
        'Respect schedules according to scheduled availability',
        'Active communication',
        'Evacuate doubts from beginning programmers',
        'Report progress continuously to managers',
        'Help managers to define tasks',
      ],
      'Jr. Software Architect': [
        'Commitment to tasks scheduled in the project',
        'Commitment to the project',
        'Autonomy in the project',
        'Decision-making capacity about the project',
        'Respect schedules according to scheduled availability',
        'Active communication',
        'Evacuate doubts from programmers',
        'Report progress continuously to managers',
        'Help managers to define tasks',
        'Train Beginning Programmers',
        'Maintenance of FrontEnd environments',
      ],
      'Software Architect': [
        'Commitment to tasks scheduled in the project',
        'Commitment to the project',
        'Autonomy in the project',
        'Decision-making capacity about the project',
        'Respect schedules according to scheduled availability',
        'Active communication',
        'Evacuate doubts from experienced programmers',
        'Report progress continuously to managers',
        'Definition of tasks in projects',
        'Train programmers',
        'Maintenance of FrontEnd / BackEnd and Database environments',
        'Software architecture planning of assigned projects',
        'Report environment needs to infrastructure',
      ],
      'Lead Software Architect': [
        'Supervision of technological processes in projects',
        'Autonomy to choose technologies according to the project',
        'Decision capacity',
        'Team leadership',
        'Wide time availability',
        'Proactive communication',
        'Evacuate doubts from software architects',
        'Definition of project technical workflow',
        'Mentoring for programmers and arq. of software',
        'Construction of BackEnd / FrontEnd / Database environments from 0.',
        'Contingency planning and risk control',
        'Report technological needs and changes of great impact to infrastructure',
        'Active reporting of projects to the Lexart board',
      ],
      'Solution Architect': [
        'Management and definition of technological processes in projects',
        'Choice of technologies according to the project',
        'Decision-making capacity on pressure and risk',
        'Team leadership',
        'Wide time availability',
        'Proactive communication',
        'Evacuate doubts from software architects',
        'Definition of project technical workflow',
        'Mentoring for various teams in the company',
        'Ability to transmit technology needs to unsuitable personnel in the matter (Commercial, Marketing, etc.)',
        'Ability to decide technological changes of great impact in conjunction with infrastructure',
        'Active reporting of decisions to the board',
      ],
    },
    AdminContinuity: {
      title: 'Continuity',
      newBtn: 'Report',
      searchPlaceholder: 'Search time report',
      headers: [ 'Id', 'User', 'Month', 'Year', 'Hours'],
      modalTitle: 'Reports',
      colaborator: 'Colaborator',
      errorMsgs: {
        month: 'Month is required',
        year: 'Invalid year',
        user: 'User is required',
        continuity: 'Please, insert a valid report, with minutes and seconds',
      },
    },
    AdminCollaborators: {
      title: 'Collaborators',
      newBtn: 'Collaborator',
      searchPlaceholder: 'Search by collaborator',
      headers: [ 'Id', 'Name', 'email', 'Type', 'Platform', 'Active' ],
      modalTitle: 'Collaborator',
      colaborator: 'Colaborator',
      successToAdd: "User created/edited successfully",
      errorToAdd: "Error trying to create/edit an user"
    },
    AdminPayments: {
      title: 'Payments',
      headers: ['Id', 'Name', 'Salary', 'Currency', 'Billing', 'Created', 'Updated'],
      modalTitle: 'Payment',
      colaborator: 'Colaborator',
      newBtn: 'Salary',
      currency: 'Currency',
      billing: 'Billing',
      promotion: 'Promotion date',
      salary: 'Salary',
      errorMsgs: {
        salary: 'Payment field is required.',
        user: 'User field is required.',
        billing: 'Billing field is required.',
        date: 'Date of promotion is required.',
        currency: 'Currency is requiered.',
      },
    },
    UserBurnoutTest: {
      warning: 'You have not taken the burnout test in the last 3 months.',
      newTest: 'New Test',
      maxOneTestPerMonth: 'Maximum one test per month',
      rangesTitle: 'MEASUREMENT SCALE RANGES',
      ranges0: "Never.",
      ranges1: "A few times a year or less.",
      ranges2: "Once a month or less.",
      ranges3: "A few times a month.",
      ranges4: "Once a week.",
      ranges5: "A few times a week.",
      ranges6: "Every day.",
      q0: "I feel emotionally drained by my work.",
      q1: "I feel tired at the end of the workday.",
      q2: "When I get up in the morning and face another day of work, I feel fatigued.",
      q3: "I find it easy to understand how my colleagues feel.",
      q4: "I think I am treating some tasks as if they were impersonal objects.",
      q5: "I feel that working all day with code is a great effort and tires me out.",
      q6: "I believe I deal very effectively with technical problems.",
      q7: "I feel burned out from my work. I feel worn out by my job.",
      q8: "I think that with my work, I am making a positive impact on my team's projects.",
      q9: "I have become more insensitive to people since I started coding.",
      q10: "I think this job is making me emotionally hardened.",
      q11: "I feel very energetic in my work.",
      q12: "I feel frustrated in my job.",
      q13: "I think I work too much.",
      q14: "I am really not concerned about what happens to some of my projects.",
      q15: "Working directly with code stresses me out.",
      q16: "I feel that I can easily create a pleasant atmosphere with my team.",
      q17: "I feel motivated after working on projects.",
      q18: "I think I achieve many valuable things in this job.",
      q19: "I feel finished at my job, at the limit of my capabilities.",
      q20: "In my job, I handle technical problems very calmly.",
      q21: "I think that team members blame me for some of their problems.",
      scoreTired: 'Emotional fatigue',
      scoreDepersonalization: 'Depersonalization',
      scoreRealization: 'Personal accomplishment',
      tooltipScore: 'Signs of burnout, more than',
    }
  },
  pt: {},
}

export default  translations;
