import axios from "axios";
import { APP_NAME, API } from '../../env';

const MODEL = 'companies/';

const buildHeaders = () => {
  
	const headers = {
	  'company_slug': localStorage.getItem("_company-slug"),
	};
  
	return headers;
  };
  
  const headers = buildHeaders();

const UtilsServices = {
	copy: function (obj) {
		return JSON.parse(JSON.stringify(obj));
	},
	getIdCompanyBySlug: async function (){
		return axios.get(`${API + MODEL}` + 'slug', {headers});
	},
	indicatorsCopy() {
		return {
			'desempeño': [
				{
					'name': 'Responsabilidad',
					'total': 3
				},
				{
					'name': 'Exactitud y calidad',
					'total': 3
				},
				{
					'name': 'Entregas en fecha',
					'total': 3
				},
				{
					'name': 'Productividad',
					'total': 3
				},
				{
					'name': 'Orden y claridad del trabajo',
					'total': 3
				},
				{
					'name': 'Status de su trabajo',
					'total': 3
				},
				{
					'name': 'Capacidad de realización',
					'total': 3
				},
				{
					'name': 'Comprensión de situaciones',
					'total': 3
				},
				{
					'name': 'Sentido Común',
					'total': 3
				},
				{
					'name': 'Cumplimiento de los procedimientos existentes',
					'total': 3
				},
				{
					'name': 'Grado de Conocimiento Técnico',
					'total': 3
				},
				{
					'name': 'Grado de Conocimiento funcional',
					'total': 3
				}
			],
			factorHumano: [
				{
					'name': 'Actitud hacia la Empresa',
					'total': 3
				},
				{
					'name': 'Actitud hacia superiores',
					'total': 3
				},
				{
					'name': 'Actitud hacia los Compañeros',
					'total': 3
				},
				{
					'name': 'Actitud con el cliente',
					'total': 3
				},
				{
					'name': 'Cooperación con el equipo',
					'total': 3
				},
				{
					'name': 'Capacidad de aceptar críticas',
					'total': 3
				},
				{
					'name': 'Capacidad de generar sugerencias constructivas',
					'total': 3
				},
				{
					'name': 'Predisposición',
					'total': 3
				}
			],
			habilidades: [
				{
					'name': 'Iniciativa',
					'total': 3
				},
				{
					'name': 'Creatividad',
					'total': 3
				},
				{
					'name': 'Adaptabilidad',
					'total': 3
				},
				{
					'name': 'Respuesta bajo presión',
					'total': 3
				},
				{
					'name': 'Capacidad de manejar múltiples tareas',
					'total': 3
				},
				{
					'name': 'Coordinación y Liderazgo',
					'total': 3
				},
				{
					'name': 'Potencialidad.',
					'total': 3
				}
			]
		}
	}
}

export default UtilsServices;